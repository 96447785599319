.project-contents {
    /* grid-template-columns: repeat(3, 1fr); */
    padding-bottom: 2.5rem;
    grid-template-rows: auto auto;
    gap: 1.5rem;
}

.project-caption {
    font-size: 1rem;
    text-transform: uppercase;

    padding-bottom: 1.5rem;
    font-weight: bold;
    color: #5c5c5c;
}

.project-text {
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-rows: auto auto;
    gap: 1.5rem;
    padding-bottom: 1.5rem;

    /* font-family: Karla, sans-serif; */
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    color: #5c5c5c;
}   