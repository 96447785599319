.project-card {
    grid-column: auto;
    /* grid-row: 1; */
    min-height: 31.25rem;
    padding-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 0rem;
    overflow: hidden;
    background-color: #ededed;
  }
  
  .project-card:hover .project-card-image img {
    transform: scale(1.1);
  }
  
  .project-card-image img {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    margin-bottom: 0.9375rem;
    transition: all ease-in-out 0.5s;
  }
  
  .project-card-header, 
  .project-card-body-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  
  .project-card-header {
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
    margin-bottom: 1.875rem;
  }
  
  .project-card-header h3 {
    font-size: 1.15rem;
    text-transform: uppercase;
  }
  
  .project-card-header span {
    font-weight: bold;
    color: #EE9972;
  }
  
  .project-card-body-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .project-card-body-footer a {
    font-weight: bold;
  }
  
  .project-card-body-footer svg {
    margin-left: 0.5rem;
  }


  /*-- Media queries ------------------------------------------------------*/
  
  @media (max-width: 960px) {
  .project-card {
    grid-row: auto;
  }
  }