.intro {
    padding-top: 0.875rem;
    align-content: center;
    /* background-color: #495E57; */
}
  
.intro .container{
  display: flex;
  gap: 3rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-content: center;
}


.intro-information h1 {
  color: #000000;
  /* background-color: black; */
  text-align: center;
  text-decoration: underline;
}

.link-container {
  display: flex;
  justify-content: center;
}

.about-sun {
  color: #000000;
  text-decoration: underline;
  font-family: "Markazi Text";
  font-size: 3rem;
}

.intro-information h3 {
  color: #363636;
  font-family: "Helvetica";
  text-align: center;
}


.intro-information p {
  margin-top: 1.5rem;
  color: #363636;
  font-family: "Helvetica";
}

.intro-information .button-primary {
  display: inline-block;
  margin-top: 2rem;
}

.intro-information .button-primary:hover, 
.intro-information .button-primary:focus {
  border-color: #8b8b8b;
}

.intro-img {
  /* grid-column: 2; */
  height: 20rem;
  /* margin-bottom: -4.375rem; */
  align-content: center;
  border-radius: 0rem;
  clip-path: circle(10rem at center);
}
  
/*-- Media queries ------------------------------------------------------*/

@media (max-width: 792px) {
.intro .container {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}

.intro-information {
  grid-row: 1;
}

.intro-image {
  grid-column: 1;
  grid-row: 2;
}
}