.project-lists {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 1.25rem;
  }
  
  .project-lists-header {
    grid-column: 1 / 4;
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 1.875rem;
  }
  
  .project-lists-header h2 {
    line-height: 1;
    font-family: "Markazi Text";
    font-weight: normal;
    font-size: 2.5rem;
  }
  
  /*-- Media queries ------------------------------------------------------*/
  
  @media (max-width: 960px) {
  .project-lists {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
  }
  
  .project-lists-header {
    grid-column: 1 / 3;
  }
  }
  
  @media (max-width: 692px) {
  .project-lists {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
  
  .project-lists-header {
    grid-column: 1 / 2;
  }
  }