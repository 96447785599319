site-footer {
    background-color: #ffffff;
    color: #000000;
  }
  
  .site-footer .container {
    grid-template-columns: repeat(4, auto);
    justify-self: center;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  
  .site-footer-logo {
    grid-column: 1;
    min-width: 3rem;
    width: 6rem;
  }
  
  .site-footer h4 {
    text-transform: uppercase;
    letter-spacing: 0.1875rem;
    margin-bottom: 1rem;
  }
  
  .site-footer a {
    color: #000000;
    transition: color 0.5s;
  }
  
  .site-footer a:hover, 
  .site-footer a:focus {
    color: #8e8e8e;
  }
  
  .site-footer-nav {
    grid-column: 2;
  }
  
  .site-footer-nav ul {
    list-style-type: none;
  }
  
  .site-footer-nav li {
    margin-bottom: 0.25rem;
  }
  
  .site-footer-contact {
    grid-column: 3;
  }
  
  .site-footer-contact svg {
    margin-right: 0.5rem;
  }
  
  .site-footer-contact p {
    margin-bottom: 0.25rem;
  }
  
  .site-footer-social {
    grid-column: 4;
  }
  
  .site-footer-social svg {
    margin-right: 1rem;
  }
  
  /*-- Media queries ------------------------------------------------------*/
  
  @media (max-width: 927px) {
  .site-footer .container {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: auto auto;
  }
  
  .site-footer-social {
    grid-column: 1;
    grid-row: 2;
    margin-top: 2rem;
  }
  }
  
  @media (max-width: 789px) {
  .site-footer .container {
    grid-template-columns: auto auto;
  }
  
  .site-footer-contact {
    grid-column: 1;
    grid-row: 2;
    margin-top: 2rem;
  }
  
  .site-footer-social {
    grid-column: 2;
  }
  }
  
  @media (max-width: 576px) {
  .site-footer .container {
    grid-template-columns: auto;
  }
  
  .site-footer-logo {
    grid-row: 1;
  }
  
  .site-footer-nav {
    grid-column: 1;
    grid-row: 2;
    margin-top: 2rem;
  }
  
  .site-footer-contact {
    grid-row: 3;
  }
  
  .site-footer-social {
    grid-column: 1;
    grid-row: 4;
  }
  }