.nav-bar {
    grid-template-columns: auto auto auto;
    min-height: 4.25rem;
}

.nav-bar-logo {
grid-column: 1;
justify-self: start;
align-self: center;
line-height: 0;
}

.nav-bar-logo img {
max-width: 6.625rem;
}

.nav-bar-hamburger {
grid-column: 2;
display: none;
cursor: pointer;
color: #000000;
transition: background-color 0.5s;
}

.nav-bar-hamburger:hover {
background-color: #8b8b8b;
}

.nav-bar-links {
grid-column: 3;
display: flex;
justify-content: right;
margin-block-start: 0;
margin-block-end: 0;
padding-inline-start: 0;
list-style-type: none;
transition: left 0.3s;
}

.nav-bar-links.expanded {
left: 0;
}

.nav-bar-links li {
display: flex;
}

.nav-bar-links a {
display: flex;
align-items: center;
padding-top: 1.5rem;
padding-left: 1rem;
padding-right: 1rem;
padding-bottom: 1.5rem;
text-transform: uppercase;
transition: background-color 0.5s;
}

.current-location, 
.nav-bar-links a:hover, 
.nav-bar-links a:focus {
text-decoration: underline;
}

@media only screen and (max-width: 992px) {
    .nav-bar {
        grid-template-columns: auto auto;
    }

    .nav-bar-hamburger {
        width: 2.5rem;
        justify-self: end;
        display: inline-block;
        background: none;
        border: none;
    }

    .nav-bar-links {
        position: absolute;
        flex-direction: column;
        width: 50%;
        height: 100vh;
        top: 0;
        left: -50%;
        background-color: #FFFFFF;
        box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.2);
        z-index: 100;
    }

    .nav-bar-links li {
        flex-direction: column;
    }
}